import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

const loadPath = 'https://prebuiltapi.streamset.pro/api/translation/{{lng}}';

i18n
	.use(Backend)
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'it',
		ns: ['default'],
		defaultNS: 'default',
		supportedLngs: ['en', 'it', 'es', 'fr'],
		backend: {
			loadPath: loadPath,
		},
	});

export default i18n;
