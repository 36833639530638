import { lazy, Suspense } from 'react';
import Loader from './componants/Loader';

const Main = lazy(() => import('./componants/Main'));

function App() {
	return <Suspense fallback={<Loader />}>{<Main />}</Suspense>;
}

export default App;
